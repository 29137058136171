import { PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { AddRoleState } from '../types';

export const initialState: AddRoleState = {
  loading: false,
  pageSize: 10,
  totalRow: 0,
  search: '',
  pageNo: 1,

  editIndex: 0,
  isadd: false,
  dissableCheck: false,
  updatePasswordList: [],
  updatePasswordFormData: { id: '', password: '', newPassword: '' }
};

export const useClinicSlice = createSlice({
  name: 'AddRoletate',
  initialState,
  reducers: {
    userUpdatePasswordFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `updatePasswordFormData.${action.payload.key}`, action.payload.value);
    },
    doUpdateUserPassword: (state, action: PayloadAction<{ callback: () => void }>) => {},
    clearPasswordForm: (state) => {
      state.updatePasswordFormData.id = '';
      state.updatePasswordFormData.password = '';
      state.updatePasswordFormData.newPassword = '';
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      console.log('Loading');
      state.loading = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      console.log('pageNo');
      state.totalRow = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      console.log('pageNo');
      state.pageNo = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      console.log('pageSize');
      state.pageSize = action.payload;
    },
    userDelete: (state, action: PayloadAction<any>) => {},
    userEdit: (state, action: PayloadAction<any>) => {},
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
