import { PayloadAction } from '@reduxjs/toolkit';
import {
  UserInterface,
  UserState,
  MealListInterface,
  ChatInterface,
  UserDataInterface,
  Message_sender_type_enum,
  UserMessageInterface,
  listSupportInterface,
  UserProfileInterface
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: UserState = {
  loading: false,
  List: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  chatList: [],
  mealList: [],
  userData: {
    _id: '',
    name: ''
  },
  sendChat: {
    userId: '',
    mealId: '',
    childId: '',
    message: ''
  },
  userMealPageNo: 1,
  userMealPageSize: 10,
  userMealTotalRow: 0,
  chatForm: {
    id: '',
    _id: '',
    userName: '',
    name: '',
    message: '',
    profilePic: '',
    senderId: '',
    receiverId: '',
    sender: Message_sender_type_enum.System,
    childId: '',
    mealId: '',
    meal: '',
    child: ''
  },
  listMessage: [],
  searchMessage: '',
  pageNoMessage: 1,
  pageSizeMessage: 10,
  totalRowMessage: 0,
  pageNoSupport: 0,
  pageSizeSupport: 0,
  totalRowSupport: 0,
  listSupport: [],
  loadingSupport: false,
  contactUsDetails: false,
  contactUsDetailsLoading: false,
  listSupportDetails: { _id: '', description: '', email: '', mobileNumber: '', name: '', queryStatus: 0 },
  userWholeData: {
    _id: '',
    name: '',
    profilePic: '',
    mobileNumber: '',
    email: '',
    isActive: 0,
    emailVerified: false,
    selectedChild: '',
    childAdded: false,
    profileCompleted: false,
    dob: '',
    createdAt: '',
    updatedAt: '',
    childProfiles: [],
    familyProfiles: [],
    plan: {
      isPlanPurchases: false,
      pricePaidByUser: 0,
      availDiscountByUser: 0,
      couponCode: '',
      transactionId: '',
      planId: '',
      planEnglishTitle: '',
      planHindiTitle: '',
      planArabicTitle: '',
      androidAmount: 0,
      iosAmount: 0,
      isDefault: 0,
      activityFree: 0,
      community: 0,
      milestone: 0,
      toy: 0,
      blogs: 0,
      tracker: 0,
      ructingType: 0,
      mealChat: 0,
      vaccination: 0,
      isActive: 0,
      order: 0
    }
  }
};

export const useClinicSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSizeMessage: (state, action: PayloadAction<number>) => {
      state.pageSizeMessage = action.payload;
    },
    setPageNoMessage: (state, action: PayloadAction<number>) => {
      state.pageNoMessage = action.payload;
    },
    setTotalRowMessage: (state, action: PayloadAction<number>) => {
      state.totalRowMessage = action.payload;
    },
    setSearchMessage: (state, action: PayloadAction<string>) => {
      state.searchMessage = action.payload;
    },
    setListMessage: (state, action: PayloadAction<Array<UserMessageInterface>>) => {
      state.listMessage = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setUserWholeData: (state, action: PayloadAction<UserProfileInterface>) => {
      state.userWholeData = action.payload;
    },
    clearUserWholeData: (state) => {
      state.userWholeData = initialState.userWholeData;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    doGetList: (state) => {},
    doGetUnreadMessage: (state) => {},
    setList: (state, action: PayloadAction<Array<UserInterface>>) => {
      state.List = action.payload;
    },
    setuserMealuserMealPageSize: (state, action: PayloadAction<number>) => {
      state.userMealPageSize = action.payload;
    },
    setuserMealPageNo: (state, action: PayloadAction<number>) => {
      state.userMealPageNo = action.payload;
    },
    setuserMealTotalRow: (state, action: PayloadAction<number>) => {
      state.userMealTotalRow = action.payload;
    },
    doGetMealList: (state, action: PayloadAction<string>) => {},
    setMealList: (state, action: PayloadAction<Array<MealListInterface>>) => {
      state.mealList = action.payload;
    },
    doGetChatList: (state, action: PayloadAction<{ userId: string; childId: string; callback: any }>) => {},
    setChatList: (state, action: PayloadAction<Array<ChatInterface>>) => {
      state.chatList = action.payload;
    },
    doGetUserData: (state) => {},
    setUserData: (state, action: PayloadAction<UserDataInterface>) => {
      state.userData = action.payload;
    },
    updateSendFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `sendChat.${action.payload.key}`, action.payload.value);
    },
    doSendChat: (state, action: PayloadAction<{ callback: any }>) => {},
    doReadMessage: (state, action: PayloadAction<{ userId: string; callback: any }>) => {},
    clearForm: (state) => {
      state.sendChat.message = '';
      state.sendChat.userId = '';
      state.sendChat.mealId = '';
    },
    doGetUserDataById: (state, action: PayloadAction<{ id:string}>) => {},
    doGetConnectUsList: (state) => {},
    setLoadingSupport: (state, action: PayloadAction<boolean>) => {
      state.loadingSupport = action.payload;
    },

    setPageSizeSupport: (state, action: PayloadAction<number>) => {
      state.pageSizeSupport = action.payload;
    },
    setPageNoSupport: (state, action: PayloadAction<number>) => {
      state.pageNoSupport = action.payload;
    },
    setTotalRowSupport: (state, action: PayloadAction<number>) => {
      state.totalRowSupport = action.payload;
    },
    setListSupport: (state, action: PayloadAction<Array<listSupportInterface>>) => {
      state.listSupport = action.payload;
    },
    doGetConnectUsDetails: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    setListSupportDetails: (state, action: PayloadAction<listSupportInterface>) => {
      state.listSupportDetails = action.payload;
    },
    setContactUsDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.contactUsDetailsLoading = action.payload;
    },
    setContactUsDetails: (state) => {
      state.contactUsDetails = !state.contactUsDetails;
    },
    clearContactUsDetails: (state) => {
      state.listSupportDetails._id = "";
      state.listSupportDetails.description = "";
      state.listSupportDetails.email = "";
      state.listSupportDetails.mobileNumber = "";
      state.listSupportDetails.name = "";
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
