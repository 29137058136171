export interface DayInterface {
  id: string;
  days: number;
  isActive: ActivityDayStatusEnum;
}
export enum ActivityDayStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface DayListItemInterface {
  id: string;
  days: number;
  isActive: number;
  totalActivity: number;
}

export interface DayState {
  list: DayInterface[];
  form: DayInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialDayState = DayState;
