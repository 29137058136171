import axios from 'axios';
import Api from './Api';
const LoginRequest = async (data: any) => {
  return Api.post(`admin/login`, data);
};
const MeProfileRequest = async () => {
  return Api.get(`auth/user/me`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const StaffPermissionRequest = async () => {
  return Api.get(`staff/get/010b7229-6fb4-4e9f-8463-992dd23e1a32`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdatePassword = async (data: any) => {
  return Api.patch(`/admin/change-password`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//milestone list//
const AddMileStoneCategoryRequest = async (data: any) => {
  return Api.post(`milestonecategory/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMileStoneCategoryListRequest = async (filter: string) => {
  return Api.get(`milestonecategory/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMileStoneEnglishCategoryListRequest = async () => {
  return Api.get(`milestonecategory/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateMileStoneCategoryRequest = async (data: any) => {
  return Api.put(`milestonecategory/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateMileStoneQuestionRequest = async (data: any) => {
  return Api.put(`/milestonequestion/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMileStoneCategoryForEditRequest = async (id: string) => {
  return Api.get(`milestonecategory/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const AddMileStoneListRequest = async (data: any) => {
  return Api.post(`/milestone/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const AddMileStoneQuestionListRequest = async (data: any) => {
  return Api.post(`/milestonequestion/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const UpdateMileStoneUpdateRequest = async (data: any) => {
  return Api.put(`/milestone/update/${data?._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetMileStoneListRequest = async (filter: string) => {
  return Api.get(`/milestone/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetQuestionListRequest = async (id: string) => {
  return Api.get(`/milestonequestion/get-all/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMilestoneListRequest = async () => {
  return Api.get(`/milestone/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetQuestionListFIlterRequest = async () => {
  return Api.get(`/milestonequestion/get-question`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const MileStoneQuestionDeleteRequest = async (id: any) => {
  return Api.delete(`/milestonequestion/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const MileStoneDeleteRequest = async (id: any) => {
  return Api.delete(`/milestone/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetQuestionListAllRequest = async (filter: string) => {
  return Api.get(`/milestonequestion/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMileStonelistByCategoryRequest = async (id: string) => {
  return Api.get(`/milestone/by-category/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMileStoneEditRequest = async (id: string) => {
  return Api.get(`/milestone/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMileStoneQuestionEditRequest = async (id: string) => {
  return Api.get(`/milestonequestion/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const postMileStoneFile = async (data: any) => {
  return Api.put(`auth/user/singed-url`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};
//blog list//
const AddBlogRequest = async (data: any) => {
  return Api.post(`blog/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetBloglistRequest = async (filter: string) => {
  return Api.get(`blog/get-all/${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetBlogEditRequest = async (id: string) => {
  return Api.get(`blog/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateBlogRequest = async (data: any) => {
  return Api.put(`blog/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const AddTrendingBlogRequest = async (data: any) => {
  return Api.post(`trendingblog/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const GetTrendingBloglistRequest = async (filter: string) => {
  return Api.get(`trendingblog${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const blogFileRequest = async (data: any) => {
  return Api.put(`blog/upload`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};

const GetTrendingBlogEditRequest = async (id: string) => {
  return Api.get(`trendingblog/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const UpdateTrendingBlogRequest = async (data: any) => {
  return Api.put(`trendingblog/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

//day list section//
const doAddDay = async (data: any) => {
  return Api.post(`dayactivity/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doUpdate = async (data: any) => {
  return Api.put(`dayactivity/update`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDayList = async (filter: string) => {
  return Api.get(`dayactivity/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetDataForEditDay = async (id: string) => {
  return Api.get(`dayactivity/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doActivityAddFile = async (data: any) => {
  return Api.put(`activity/upload`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'multipart/form-data'
    }
  });
};

const doAddActivity = async (data: any) => {
  return Api.post(`activity/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doUpdateActivity = async (data: any) => {
  return Api.put(`activity/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetActivitylist = async (filter: string) => {
  return Api.get(`activity/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetActivitylistAll = async (filter: string) => {
  return Api.get(`activity/all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetActivityEdit = async (id: string) => {
  return Api.get(`activity/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetCategoryMilestone = async (id: string) => {
  return Api.get(`milestonequestion/get-milestone-details/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMilestoneQuestion = async (id: string) => {
  return Api.get(`milestonequestion/by-milestone/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

// file upload
const getDayAcivityImageRequest = async (data: any) => {
  return Api.post(
    `/activity/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const getDayVideoRequest = async (data: any) => {
  return Api.post(
    `/activity/video/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const getMealsVideoRequest = async (data: any) => {
  return Api.post(
    `/meal/video/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const getDayBlogImageRequest = async (data: any) => {
  return Api.post(
    `/blog/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const getDayMilestoneImageRequest = async (data: any) => {
  return Api.post(
    `/milestone/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const getDayMealImageRequest = async (data: any) => {
  return Api.post(
    `/meal/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const getDayIngridintImageRequest = async (data: any) => {
  return Api.post(
    `/mealingredients/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const uplodeDayAcivityImageRequest = async (signedUrl: any, data: any, contentType: any) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      'Content-Type': contentType
    }
  });
};
//meal section start//
const doAddMeal = async (data: any) => {
  return Api.post(`/meal/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealList = async (filter: string) => {
  return Api.get(`/meal/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealEdit = async (id: string) => {
  return Api.get(`/meal/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateMeal = async (data: any) => {
  return Api.put(`/meal/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//meal section end//
//toy section start//
const doAddToy = async (data: any) => {
  return Api.post(`/toy/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetToysList = async (filter: string) => {
  return Api.get(`/toy/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetToyEdit = async (id: string) => {
  return Api.get(`/toy/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateToy = async (data: any) => {
  return Api.put(`/toy/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//toy section end//
//plan section start//
const doAddPlan = async (data: any) => {
  return Api.post(`/plan/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddStaffRights = async (data: any) => {
  return Api.post(`/staff/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doAddVaccination = async (data: any) => {
  return Api.post(`/vaccination/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetPlanList = async (filter: string) => {
  return Api.get(`/plan/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetStaffList = async (filter: string) => {
  return Api.get(`/staff/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetVaccsinationList = async (filter: string) => {
  return Api.get(`/vaccination/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetPlanEdit = async (id: string) => {
  return Api.get(`/plan/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetStaffEdit = async (id: string) => {
  return Api.get(`/staff/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetVaccinationEdit = async (id: string) => {
  return Api.get(`/vaccination/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doUpdatePlan = async (data: any) => {
  return Api.put(`/plan/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateStaff = async (data: any) => {
  return Api.put(`/staff/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//plan section end//
// vaccination //
const doUpdateVaccination = async (data: any) => {
  return Api.put(`/vaccination/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
// user //
const doGetUserList = async (filter: string) => {
  return Api.get(`/admin/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetRevenueList = async (filter: string) => {
  return Api.get(`/order/get-revenue-list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetExcleExportList = async (filter: string) => {
  return Api.get(`/order/export-revenue-excel${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetContactsList = async (filter: string) => {
  return Api.get(`/contact-us/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetUnreadMessageList = async (filter: string) => {
  return Api.get(`/chat/admin/get-all-unread${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetUserMealList = async (filter: string) => {
  return Api.get(`/chat/get-messaged-meal${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetChatList = async (filter: string) => {
  return Api.get(`/chat/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doReadMessage = async (id: string) => {
  return Api.put(
    `/chat/admin/read-message/${id}`,
    {},
    {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
  );
};
const doSendChat = async (data: any) => {
  return Api.post(`/chat/send-message`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
// permission //
const doGetUserPermissionList = async (filter: string) => {
  return Api.get(`${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
// feedback //
const doGetFeedbackList = async (filter: string) => {
  return Api.get(`${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

// help Me //
const doAddHelp = async (data: any) => {
  return Api.post(`faq/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetHelplistRequest = async (filter: string) => {
  return Api.get(`faq/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetHelpEditRequest = async (id: string) => {
  return Api.get(`faq/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateHelpRequest = async (data: any) => {
  return Api.put(`faq/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//meals Category list//
const doAddMealsCategory = async (data: any) => {
  return Api.post(`mealcategory/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsCategoryList = async (filter: string) => {
  return Api.get(`mealcategory/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsCategoryById = async (id: string) => {
  return Api.get(`mealcategory/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateMealsCategory = async (data: any) => {
  return Api.put(`mealcategory/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsCategoryListAll = async () => {
  return Api.get(`mealcategory/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//meals ingreadient list//
const doAddMealsIngredient = async (data: any) => {
  return Api.post(`mealingredients/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsIngredientList = async (filter: string) => {
  return Api.get(`mealingredients/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsIngredientById = async (id: string) => {
  return Api.get(`mealingredients/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateMealsIngredient = async (data: any) => {
  return Api.put(`mealingredients/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsIngredientListAll = async () => {
  return Api.get(`mealingredients/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//toy category list//
const doAddToysCategory = async (data: any) => {
  return Api.post(`toycategory/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetToysCategoryList = async (filter: string) => {
  return Api.get(`toycategory/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetToysCategoryById = async (id: string) => {
  return Api.get(`toycategory/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateToysCategory = async (data: any) => {
  return Api.put(`toycategory/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetToysCategoryListAll = async () => {
  return Api.get(`toycategory/all`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//meals servings list//
const doAddMealServings = async (data: any) => {
  return Api.post(`mealservings/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealServingsList = async (filter: string) => {
  return Api.get(`mealservings/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealServingsById = async (id: string) => {
  return Api.get(`mealservings/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateMealServings = async (data: any) => {
  return Api.put(`mealservings/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//meal section start//
const doAddMealsFunFood = async (data: any) => {
  return Api.post(`/fun-food/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsFunFoodList = async (filter: string) => {
  return Api.get(`/fun-food/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetMealsFunFoodEdit = async (id: string) => {
  return Api.get(`/fun-food/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateMealsFunFood = async (data: any) => {
  return Api.put(`/fun-food/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetMilestoneRequest = async () => {
  return Api.get(`/activity/milestone-list`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//coupon section start//
const doAddCoupon = async (data: any) => {
  return Api.post(`/coupon/create`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetCouponList = async (filter: string) => {
  return Api.get(`/coupon/list${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetCouponEdit = async (id: string) => {
  return Api.get(`/coupon/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doUpdateCoupon = async (data: any) => {
  return Api.put(`/coupon/update/${data._id}`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
//coupon section end//
const doAddTutorial = async (data: any) => {
  return Api.post(`/tutorial/add`, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetTutorialList = async (filter: string) => {
  return Api.get(`/tutorial/get-all${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const tutorialDeleteRequest = async (id: any) => {
  return Api.delete(`/tutorial/delete/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const GetContactsDetails = async (id: string) => {
  return Api.get(`/contact-us/get/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetUserDataById = async (id: string) => {
  return Api.get(`admin/user-detail/${id}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetDashboardData = async (filter: string) => {
  return Api.get(`admin/dashboard${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
const doGetDashboardChartData = async (filter: string) => {
  return Api.get(`admin/dashboard-graph${filter}`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};

const doGetDashboardChartDataRequest = async () => {
  return Api.get(`order/recent-order`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
};
export {
  doGetDashboardChartDataRequest,
  doGetDashboardChartData,
  doGetDashboardData,
  doGetExcleExportList,
  doGetRevenueList,
  doGetUserDataById,
  tutorialDeleteRequest,
  doGetTutorialList,
  doAddTutorial,
  GetMilestoneRequest,
  doGetStaffEdit,
  LoginRequest,
  MeProfileRequest,
  UpdatePassword,
  GetMileStoneEnglishCategoryListRequest,
  uplodeDayAcivityImageRequest,
  getDayAcivityImageRequest,
  doAddDay,
  doUpdate,
  doActivityAddFile,
  doAddActivity,
  doUpdateActivity,
  doGetActivitylist,
  doGetActivitylistAll,
  doGetActivityEdit,
  doGetDataForEditDay,
  doGetDayList,
  doAddPlan,
  doGetPlanList,
  doGetPlanEdit,
  doUpdatePlan,
  doGetUserList,
  doGetUserPermissionList,
  doGetFeedbackList,
  doAddHelp,
  doGetHelplistRequest,
  doUpdateHelpRequest,
  doGetHelpEditRequest,
  doGetUnreadMessageList,
  AddBlogRequest,
  AddTrendingBlogRequest,
  blogFileRequest,
  GetBloglistRequest,
  GetTrendingBloglistRequest,
  GetBlogEditRequest,
  GetTrendingBlogEditRequest,
  UpdateBlogRequest,
  UpdateTrendingBlogRequest,
  GetQuestionListRequest,
  GetQuestionListAllRequest,
  GetMileStonelistByCategoryRequest,
  MileStoneQuestionDeleteRequest,
  AddMileStoneListRequest,
  GetMileStoneListRequest,
  GetMileStoneEditRequest,
  AddMileStoneCategoryRequest,
  UpdateMileStoneCategoryRequest,
  GetMileStoneCategoryListRequest,
  GetMileStoneCategoryForEditRequest,
  doGetMilestoneQuestion,
  postMileStoneFile, //file upload,
  AddMileStoneQuestionListRequest,
  UpdateMileStoneUpdateRequest,
  GetMileStoneQuestionEditRequest,
  UpdateMileStoneQuestionRequest,
  doAddVaccination,
  doGetVaccsinationList,
  doGetCategoryMilestone,
  doGetVaccinationEdit,
  doUpdateVaccination,
  MileStoneDeleteRequest,
  doAddMeal,
  doGetMealList,
  doGetMealEdit,
  doUpdateMeal,
  getDayMilestoneImageRequest,
  getDayBlogImageRequest,
  getDayMealImageRequest,
  getDayVideoRequest,
  doAddStaffRights,
  doGetStaffList,
  doUpdateStaff,
  getMealsVideoRequest,
  GetMilestoneListRequest,
  GetQuestionListFIlterRequest,
  StaffPermissionRequest,
  doAddToy,
  doGetToysList,
  doGetToyEdit,
  doReadMessage,
  doUpdateToy,
  doGetMealsIngredientById,
  doUpdateMealsIngredient,
  doGetMealsIngredientListAll,
  doGetMealsIngredientList,
  doAddMealsIngredient,
  doUpdateMealsCategory,
  doGetMealsCategoryById,
  doGetMealsCategoryListAll,
  doGetMealsCategoryList,
  doAddMealsCategory,
  doAddToysCategory,
  doGetToysCategoryList,
  doGetToysCategoryById,
  doUpdateToysCategory,
  doGetToysCategoryListAll,
  doAddMealServings,
  doGetMealServingsList,
  doGetMealServingsById,
  doUpdateMealServings,
  doGetUserMealList,
  doGetChatList,
  doSendChat,
  doAddMealsFunFood,
  doGetMealsFunFoodList,
  doGetMealsFunFoodEdit,
  doUpdateMealsFunFood,
  doAddCoupon,
  doGetCouponList,
  doGetCouponEdit,
  doUpdateCoupon,
  getDayIngridintImageRequest,
  doGetContactsList,
  GetContactsDetails
};
