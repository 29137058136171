export interface CouponInterface {
  _id: string;
  englishTitle: string;
  hindiTitle: string;
  arabicTitle: string;
  code: string;
  value: number;
  expiryDate: string;
  isActive: ActivityEnum;
  availability: CouponAvailabilityTypeEnum;
}
export enum ActivityEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export enum CouponAvailabilityTypeEnum {
  ONE_TIME = 1,
  MULTIPLE_TIME = 2
}

export interface CouponState {
  List: CouponInterface[];
  form: CouponInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialCouponState = CouponState;
