import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { HelpMeInterface } from '../types';
import { SelectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import { doAddHelp, doGetHelplistRequest, doGetHelpEditRequest, doUpdateHelpRequest } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: HelpMeInterface = yield select(SelectForm);

  if (form.englishQuestion.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english question',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.hindiQuestion.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill hindi question',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.arabicQuestion.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill arabic question',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishAnswer.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english answer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.hindiAnswer.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill hindi answer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.arabicAnswer.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill arabic answer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddHelp, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetHelplistRequest,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetHelpEditRequest, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: HelpMeInterface = yield select(SelectForm);

  if (form.englishQuestion.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english question',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.hindiQuestion.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill hindi question',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.arabicQuestion.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill arabic question',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.englishAnswer.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill english answer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.hindiAnswer.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill hindi answer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.arabicAnswer.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill arabic answer',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdateHelpRequest, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* HelpMeRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
}
