import { PayloadAction } from '@reduxjs/toolkit';
import {
  FeedbackInterface,
  FeedbackState,
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';

export const initialState: FeedbackState = {
  loading: false,
  List: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: ''
};

export const useClinicSlice = createSlice({
  name: 'feedbackState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    doGetList: (state) => {},
    setList: (state, action: PayloadAction<Array<FeedbackInterface>>) => {
      state.List = action.payload;
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
