import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
// import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLogin } from 'sections/auth/redux/selector';
import { actions } from 'sections/auth/redux/slice';
// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const dispatch = useDispatch();
  const islogin = useSelector(selectIsLogin);
  const navigate = useNavigate();
  const fetchMe = () => {
    dispatch(actions.getUserProfile());
    dispatch(actions.getStaffPermission({
      callback() {
        
      },
    }));
  };
  useEffect(() => {
    fetchMe();
    return () => {};
  }, []);

  useEffect(() => {
    console.log('isLoggedIn', islogin);

    if (!islogin) {
      navigate('login', { replace: true });
    }
  }, [islogin, navigate]);

  return children;
};

export default AuthGuard;
