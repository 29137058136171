export interface MilestoneListInterface {
  id: string;
  _id: string;
  categoryId: string;
  imageStatus: Milestoneimagenterface[];
  image: string;
  englishTitle: string;
  categoryEnglishTitle: string;
  hindiTitle: string;
  arabicTitle: string;
  englishDescription: string;
  hindiDescription: string;
  totalQuestion :number;
  arabicDescription: string;
  isActive: ActivityDayStatusEnum;

}
export interface Milestoneimagenterface {
    isDelete : boolean
    image: string;
}
export enum ActivityDayStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface MileStoneState {
  list: MilestoneListInterface[];
  form: MilestoneListInterface;
  QuestionCategoryList: MilestoneListInterface[];
  QuestionActivityList: MilestoneListInterface[];
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  category: string;
  MileStoneCategroyList: MilestoneListInterface[];
}

export type InitialMileStoneStata = MileStoneState;
