import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { ActivityDayStatusEnum, MileStoneState, MilestoneListInterface, Milestoneimagenterface } from '../types';
import { set } from 'lodash';

export const initialState: MileStoneState = {
  loading: false,
  editIndex: 0,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  category: '',
  list: [],
  form: {
    id: '',
    _id: '',
    categoryId: '',
    hindiTitle: '',
    arabicTitle: '',
    image: '',
    imageStatus: [],
    englishTitle: '',
    englishDescription: '',
    hindiDescription: '',
    arabicDescription: '',
    isActive: ActivityDayStatusEnum.ACTIVE,
    categoryEnglishTitle: '',
    totalQuestion: 0
  },
  QuestionCategoryList: [],
  QuestionActivityList: [],
  MileStoneCategroyList: [],
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'mileStoneState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    },
    setimage: (state, action: PayloadAction<Milestoneimagenterface>) => {
      state.form.imageStatus.push(action.payload);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.categoryId = '';
      state.form.englishTitle = '';
      state.form.hindiTitle = '';
      state.form.arabicTitle = '';
      state.form.hindiDescription = '';
      state.form.arabicDescription = '';
      state.form.englishDescription = '';
      state.form.isActive = ActivityDayStatusEnum.ACTIVE;
      state.form.image = '';
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => { },
    doGetList: (state) => { },
    doGetEdit: (state, action: PayloadAction<string>) => { },
    doGetDelete: (state, action: PayloadAction<{ id:string, callback: () => void }>) => { },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => { },
    setList: (state, action: PayloadAction<Array<MilestoneListInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<MilestoneListInterface>) => {
      state.form._id = action.payload._id;
      state.form.arabicDescription = action.payload.arabicDescription;
      state.form.arabicTitle = action.payload.arabicTitle;
      state.form.categoryEnglishTitle = action.payload.categoryEnglishTitle;
      state.form.categoryId = action.payload.categoryId;
      state.form.englishDescription = action.payload.englishDescription;
      state.form.image = action.payload.image;
      state.form.englishTitle = action.payload.englishTitle;
      state.form.hindiDescription =  action.payload.hindiDescription;
      state.form.hindiTitle = action.payload.hindiTitle;
      state.form.isActive =  action.payload.isActive;
      if (action.payload.image) {
        state.form.imageStatus.push({
          image : action.payload.image,
          isDelete :  false
        });
      } else {
        state.form.imageStatus.push({
          image : "",
          isDelete :  false
        });
      }

    },
    getMilestoneByCategory: (state, action: PayloadAction<string>) => { },
    setMilestoneByCategory: (state, action: PayloadAction<Array<MilestoneListInterface>>) => {
      state.MileStoneCategroyList = action.payload;
    },
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => { },
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; result : any;callback: any }>) => { },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
