import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import {
  SelectPageNo,
  SelectPageSize,
  SelectSearch,
  selectUserMealPageNo,
  selectUserMealPageSize,
  selectSendChatData,
  SelectPageNoMessage,
  SelectPageSizeMessage,
  SelectSearchMessage,
  SelectPageNoSupport,
  SelectPageSizeSupport
} from './selector';
import {
  doGetUserList,
  doGetUserMealList,
  doGetChatList,
  doSendChat,
  doGetUnreadMessageList,
  doReadMessage,
  doGetContactsList,
  doGetUserDataById,
  GetContactsDetails
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { ChatInterface } from '../types';

export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetUserList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.meta ? response.data.meta.total : 0));
    yield put(actions.setList(response.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetUserMealListRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const pageNo: number = yield select(selectUserMealPageNo);
    const pageSize: number = yield select(selectUserMealPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetUserMealList,
      `?userId=${action.payload}&pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setuserMealTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setMealList(response.data.data.results));
    yield put(actions.setUserData(response.data.data.userData));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetChatListRequest(action: { payload: { userId: string; childId: string; callback: any } }) {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetChatList,
      `?userId=${action.payload.userId}&childId=${action.payload.childId}&pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setChatList(response.data.data.results));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doSendChatRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: ChatInterface = yield select(selectSendChatData);

  if (form.message.length == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Message Required',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doSendChat, form);
    yield put(actions.setLoading(false));
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetUnreadMessageRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNoMessage);
    const pageSize: number = yield select(SelectPageSizeMessage);
    const searchText: string = yield select(SelectSearchMessage);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetUnreadMessageList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRowMessage(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setListMessage(response.data.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doReadMessageRequest(action: { payload: { userId: string; callback: any } }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doReadMessage, action.payload.userId);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* doGetConnectUsListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNoSupport);
    const pageSize: number = yield select(SelectPageSizeSupport);
    yield put(actions.setLoadingSupport(true));
    const response: AxiosResponse = yield call(doGetContactsList, `?pageNo=${pageNo}&pageSize=${pageSize}`);
    yield put(actions.setLoadingSupport(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setTotalRowSupport(response.data.data[0].meta ? response.data.data[0].meta.total : 0));
    yield put(actions.setListSupport(response.data.data[0].results));
  } catch (error: any) {
    yield put(actions.setLoadingSupport(false));
    CatchBlockFunction(error);
  }
}
export function* doGetConnectDetailsRequest(action: { payload: { id: string; callback: any } }) {
  yield delay(500);

  try {
    yield put(actions.setContactUsDetailsLoading(true));
    const response: AxiosResponse = yield call(GetContactsDetails, action.payload.id);
    yield put(actions.setContactUsDetailsLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setListSupportDetails(response.data.data));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setContactUsDetailsLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetUserDataByIdRequest(action: { payload: { id: string;} }) {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetUserDataById, action.payload.id);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setUserWholeData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UserRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetMealList, doGetUserMealListRequest);
  yield takeLatest(actions.doGetChatList, doGetChatListRequest);
  yield takeLatest(actions.doSendChat, doSendChatRequest);
  yield takeLatest(actions.doGetUnreadMessage, doGetUnreadMessageRequest);
  yield takeLatest(actions.doReadMessage, doReadMessageRequest);
  yield takeLatest(actions.doGetConnectUsList, doGetConnectUsListRequest);
  yield takeLatest(actions.doGetConnectUsDetails, doGetConnectDetailsRequest);

  yield takeLatest(actions.doGetUserDataById, doGetUserDataByIdRequest);
}
