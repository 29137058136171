export interface BlogInterface {
  categoryId: string;
  id: string;
  _id: string;
  englishTitle: string;
  hindiTitle: string;
  arabicTitle: string;
  image: string;
  maxAge : number;
  minAge: number;
  imageStatus: imagenterface[];
  categoryEnglishTitle: string;
  categoryHindiTitle: string;
  categoryArabicTitle: string;
  age: number;
  englishDescription: string;
  hindiDescription: string;
  arabicDescription: string;
  credits: string;
  isActive: isActiveEnum;
  englishShortDescription: string;
  hindiShortDescription: string;
  arabicShortDescription: string;
}
export enum isActiveEnum {
  active = 1,
  notActive = 0
}
export interface imagenterface {
  isDelete : boolean
  image: string;
}
export interface BlogState {
  List: BlogInterface[];
  formData: BlogInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  handleChange: any;
  sortColumn: string;
  sortDir: string;
  Disabled :boolean
}

export type InitialBlogState = BlogState;
