export interface StaffInterface {
  id: string;
  _id: string;
  name: string;
  email: string;
  password: string;
  isActive: IsActiveEnum;
  module: moduleInterface[]
}
export interface moduleInterface {
 name: string;
  value: string;
 rights: rightsInterface[]
}
export interface rightsInterface {
  title: string;
  name: string;
  value: number
}
export enum IsActiveEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface StaffState {
  List: StaffInterface[];
  form: StaffInterface;
  modulesList : moduleInterface[]
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialStaffState = StaffState;
