import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { doAddTutorial, doGetTutorialList, tutorialDeleteRequest } from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { SelectPageNo, SelectPageSize, SelectSortColumn, SelectSortDir, selectForm } from './selector';
import { SettingInterface } from '../types';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: SettingInterface = yield select(selectForm);
  if (form.englishTitle.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please enter title',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  if (form.link.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please enter link',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    const response: AxiosResponse = yield call(doAddTutorial, form);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetTutorialList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${sortColumn.length > 0 ? '&sortColumn=' + sortColumn : ''}${
        sortDir.length > 0 ? '&sortDir=' + sortDir : ''
      }`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doDeleteRequest(action: {
  payload: {
    id: string;
    callback: any;
  };
}) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(tutorialDeleteRequest, action.payload.id);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* SettingRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doDelete, doDeleteRequest);
}
