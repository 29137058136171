export interface UserInterface {
  userName: string;
  gender: GenderEnum;
  id: string;
  _id: string;
  username: string;
  email: string;
  phone: string;
  usertype: number;
  __v: number;
  selectedChild: string;
  name: string;
  profilePic: string;
}
export interface UserMessageInterface {
  _id: string;
  chatId: string;
  userId: string;
  name: string;
  email: string;
  profilePic: string;
  message: string;
  unReadMessageCount: number;
  createdAt: string;
  updatedAt: string;
}
export interface ChatInterface {
  id: string;
  _id: string;
  userName: string;
  name: string;
  message: string;
  profilePic: string;
  senderId: string;
  receiverId: string;
  sender: Message_sender_type_enum;
  childId: string;
  mealId: string;
  meal: string;
  child: string;
}
export interface MealListInterface {
  _id: string;
  weekOfGestation: number;
  gender: number;
  dob: string;
  updatedAt: string;
  childId: string;
  child: string;
}
export interface SendChatInterface {
  userId: string;
  mealId: string;
  childId: string;
  message: string;
}

export enum Message_sender_type_enum {
  System = 1,
  User = 2
}
export interface UserDataInterface {
  _id: string;
  name: string;
}
export enum GenderEnum {
  FEMALE = 0,
  MALE = 1
}
export enum MealRecipeEnum {
  NORMAL = 1,
  CHEF = 2
}
export enum MealStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0
}

export interface listSupportInterface {
  _id: string;
  name: string;
  email: string;
  mobileNumber: string;
  description: string;
  queryStatus: number;
}

interface ChildProfile {
  _id: string;
  userId: string;
  name: string;
  weekOfGestation: number;
  gender: number;
  dob: string;
  profilePic: string;
  createdAt: string;
  updatedAt: string;
  isSelected: boolean;
}

interface FamilyProfile {
  _id: string;
  userId: string;
  name: string;
  dob: string;
  gender: number;
  relation: string;
  mobileNumber: string;
  mobileVerify: boolean;
  email: string;
  profilePic: string;
  createdAt: string;
  updatedAt: string;
}

interface Plan {
  isPlanPurchases: boolean;
  pricePaidByUser: number;
  availDiscountByUser: number;
  couponCode: string;
  transactionId: string;
  planId: string;
  planEnglishTitle: string;
  planHindiTitle: string;
  planArabicTitle: string;
  androidAmount: number;
  iosAmount: number;
  isDefault: number;
  activityFree: number;
  community: number;
  milestone: number;
  toy: number;
  blogs: number;
  tracker: number;
  ructingType: number;
  mealChat: number;
  vaccination: number;
  isActive: number;
  order: number;
}

export interface UserProfileInterface {
  _id: string;
  name: string;
  profilePic: string;
  mobileNumber: string;
  email: string;
  isActive: number;
  emailVerified: boolean;
  selectedChild: string;
  childAdded: boolean;
  profileCompleted: boolean;
  dob: string;
  createdAt: string;
  updatedAt: string;
  childProfiles: ChildProfile[];
  familyProfiles: FamilyProfile[];
  plan: Plan;
}

// Use the UserProfile interface wherever you need to work with the response data

export interface UserState {
  List: UserInterface[];
  chatList: ChatInterface[];
  userWholeData :UserProfileInterface;
  chatForm: ChatInterface;
  mealList: MealListInterface[];
  userData: UserDataInterface;
  sendChat: SendChatInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  userMealPageNo: number;
  userMealPageSize: number;
  userMealTotalRow: number;
  search: string;
  listMessage: UserMessageInterface[];
  searchMessage: string;
  pageNoMessage: number;
  pageSizeMessage: number;
  totalRowMessage: number;

  pageNoSupport: number;
  pageSizeSupport: number;
  totalRowSupport: number;
  listSupport: listSupportInterface[];
  listSupportDetails: listSupportInterface;
  loadingSupport: boolean;
  contactUsDetails: boolean;
  contactUsDetailsLoading: boolean;
}

export type InitialUserState = UserState;
