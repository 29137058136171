import { PayloadAction } from '@reduxjs/toolkit';
import {
  StaffState,
  IsActiveEnum,
  StaffInterface
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: StaffState = {
  loading: false,
  form: {
    id: '',
    _id: '',
    name: '',
    email: '',
    password: '',
    isActive: IsActiveEnum.ACTIVE,
    module: []
  },
  List: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: '',
  modulesList: [
    {
      name: "Milestone",
      value: "milestone",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    {
      name: "Blog",
      value: "blog",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    {
      name: "Meal",
      value: "meal",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
     {
      name: "Toys",
      value: "toys",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },


    {
      name: "Vaccination",
      value: "vaccination",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    {
      name: "Plan",
      value: "plan",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    {
      name: "User",
      value: "user",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    {
      name: "Revenue",
      value: "revenue",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    {
      name: "Permission",
      value: "permission",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
     {
      name: "Coupon",
      value: "coupon",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    
    {
      name: "Feedback",
      value: "feedback",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
    {
      name: "Help me",
      value: "help_me",
      rights: [
        { title: "Can View", name: "can_view", value: 0 },
        { title: "Can Add", name: "can_add", value: 0 },
        { title: "Can Update", name: "can_update", value: 0 },
        { title: "Can Delete", name: "can_delete", value: 0 },
      ],
    },
  ]
};

export const useClinicSlice = createSlice({
  name: 'staffState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setModule: (state, action: PayloadAction<{row :any, name: any, index: number, innerIndex: number }>) => {
      
      set(state, `modulesList[${action.payload.index}].rights[${action.payload.innerIndex}].value`,action.payload.name.value == 0 ?  1 : 0);
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    clearForm: (state) => {
      state.form.id = '';
      state.form._id = '';
      state.form.name = '';
      state.form.email = '';
      state.form.password = '';
      state.form.isActive = IsActiveEnum.ACTIVE;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => { },
    doGetList: (state) => { },
    doGetEdit: (state, action: PayloadAction<string>) => { },
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => { },

    setList: (state, action: PayloadAction<Array<StaffInterface>>) => {
      state.List = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<StaffInterface>) => {
      state.form = action.payload;
      let userright = state.modulesList;
      for (let index = 0; index < userright.length; index++) {
        const element = userright[index];
        let itemms = action.payload.module.filter((x: any) => x.name == element.name);
        if (itemms.length > 0) {
          if (itemms[0].rights) {
            for (
              let indexright = 0;
              indexright < element.rights.length;
              indexright++
            ) {
              if (
                itemms[0].rights.filter(
                  (x: any) => x.name == element.rights[indexright].name
                ).length > 0
              ) {
                element.rights[indexright].value = itemms[0].rights.filter(
                  (x: any) => x.name == element.rights[indexright].name
                )[0].value;
              }
            }
          }
        }
        userright[index] = element;
      }
      set(state, `modulesList`, userright);
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
