import { PayloadAction } from '@reduxjs/toolkit';
import { DayState, DayListItemInterface, ActivityDayStatusEnum } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: DayState = {
  loading: false,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  editIndex: 0,
  list: [],
  form: { id: '', days: 0, isActive: 1 },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'dayState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    doUpdate: (state, actions: PayloadAction<{ callback: () => void }>) => {},
    clearForm: (state) => {
      state.form.id = '';
      state.form.days = 0;
      state.form.isActive = ActivityDayStatusEnum.ACTIVE;
    },
    doAdd: (state, actions: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    setList: (state, action: PayloadAction<Array<DayListItemInterface>>) => {
      state.list = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
