import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { SelectPageNo, SelectPageSize, SelectSearch } from './selector';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { doGetRevenueList ,doGetExcleExportList } from 'utils copy/request';
import { path } from 'utils copy/Api';

export function* doGetListRequest() {
  yield delay(500);

  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetRevenueList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&code=' + searchText : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* ddoGetExcelExportRequest() {
  yield delay(500);

  try {
    const searchText: string = yield select(SelectSearch);
    const response: AxiosResponse = yield call(
      doGetExcleExportList,
      `?${searchText.length > 0 ? 'code=' + searchText : ''}&token=${localStorage.getItem('token')}`
    );
    debugger
    window.open(path() + '/static/csv/' + response.data.filename, '_blank');
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* RevenueRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetExcelExport, ddoGetExcelExportRequest);
  
}
