import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';
import { RootState } from 'newTypes/RootState';

const selectDomain = (state: RootState) => {
  console.log('state.MarkupState', state);

  if (state && state.DashboardState) {
    return state.DashboardState;
  } else {
    return initialState;
  }
};
export const SelectDashboardDataList = createSelector([selectDomain], (state) => state.DashboardList);
export const SelectDashboardGraphData = createSelector([selectDomain], (state) => state.DashboardGraphDataList);
export const SelectDashboardWalletata = createSelector([selectDomain], (state) => state.DashboardWallet);
export const SelectDashboardSearchData = createSelector([selectDomain], (state) => state.DashboardSearchList);
export const SelectLoading = createSelector([selectDomain], (state) => state.loading);
export const SelectPageNo = createSelector([selectDomain], (state) => state.pageNo);
export const SelectPageSize = createSelector([selectDomain], (state) => state.pageSize);
export const SelectTotalRow = createSelector([selectDomain], (state) => state.totalRow);
export const SelectSearch = createSelector([selectDomain], (state) => state.search);
export const SelectDisableCheck = createSelector([selectDomain], (state) => state.dissableCheck);
export const SelectdateFilter = createSelector([selectDomain], (state) => state.dateFilter);
export const SelectRecentPlanData = createSelector([selectDomain], (state) => state.recentPlanData);
export const SelectStartAndEndDate = createSelector([selectDomain], (state) => state.startAndEndDate);

export const SelectChartData = createSelector([selectDomain], (state) => state.chartData);
