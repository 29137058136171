import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import {  SettingState } from '../types';

export const initialState: SettingState = {
  form: {
    _id: '',
    englishTitle: '',
    arabicTitle: '',
    hindiTitle: '',
    link: '',
    sortOrder: 0,
    createdAt: '',
    updatedAt: ''
  },
  List: [],
  loading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'SettingState',
  initialState,
  reducers: {
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setList: (state, action: PayloadAction<any>) => {
      state.List = action.payload;
    },
    clearForm: (state) => {
      state.form._id = '';
      state.form.englishTitle = '';
      state.form.createdAt = '';
      state.form.arabicTitle = '';
      state.form.hindiTitle = '';
      state.form.link = '';
      state.form.sortOrder = 0;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doDelete: (state, action: PayloadAction<{id:string, callback: () => void }>) => { },
  }  
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
