import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import { doGetDayList, doAddDay, doUpdate } from '../../../utils copy/request';
import { SelectForm, SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir } from './selector';
import { DayInterface } from '../types';
import { actions } from './slice';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { SelectMilestone } from 'pages/milestoneList/questionList/redux/selector';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: DayInterface = yield select(SelectForm);

  if (form.days == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill days',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddDay, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }

    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const sortColumn: string = yield select(SelectSortColumn);
    const sortDir: string = yield select(SelectSortDir);
    const milestoneId: string = yield select(SelectMilestone);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetDayList,
      `?pageNo=${pageNo}&pageSize=${pageSize}&milestoneId=${milestoneId}${searchText.length > 0 ? '&search=' + searchText : ''}/${
        sortColumn.length > 0 ? 'sortColumn=' + sortColumn : ''
      }${sortDir.length > 0 ? '&sortDir=' + sortDir : ''}`
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: DayInterface = yield select(SelectForm);

  if (form.days == 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'please fill days',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doUpdate, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );

      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );

    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* DayListRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
}
