export interface ToysInterface {
  id: string;
  _id: string;
  categoryId: string;
  categoryEnglishTitle: string;
  englishDescription: string;
  hindiDescription: string;
  arabicDescription: string;
  shortEnglishDescription: string;
  shortHindiDescription: string;
  shortArabicDescription: string;
  englishTitle: string;
  hindiTitle: string;
  arabicTitle: string;
  minPrice: number;
  maxPrice: number;
  currency: CurrencyEnum;
  productLink: string;
  image: string;
  isActive: ToysStatusEnum;
  imageStatus: ImageInterface[];
  minAge: number;
  maxAge: number;
  trending: ToyTrendingTypeEnum;
  toysCategory: string;
}
export interface ImageInterface {
  isDelete: boolean;
  image: string;
}
export enum ToysStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0
}
export enum CurrencyEnum {
  INR = 1,
  USD = 2,
  CUSD = 3
}

export enum ToyTrendingTypeEnum {
  TRAINDING = 1,
  NOT_TRAINDING = 2
}
export interface ToysState {
  list: ToysInterface[];
  form: ToysInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialToysState = ToysState;
