import { PayloadAction } from '@reduxjs/toolkit';
import { ActivityState, ActivityListInterface, CategoryMilestoneInterface, Imagenterface, englishVideoStatusInterface, hindiVideoStatusInterface, arabicVideoStatusInterface } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';
import { ActivityDayStatusEnum } from 'pages/dayList/types';

export const initialState: ActivityState = {
  loading: false,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: "",
  editIndex: 0,
  list: [],
  listAll: [],
  form: {
    id: "",
    _id: "",
    minAge: 0,
    maxAge: 10,
    categoryId: "",
    arabicDescription: "",
    arabicMaterial: "",
    arabicPurpose: "",
    arabicTitle: "",
    englishDescription: "",
    // englishMaterial:  "",
    englishPurpose: "",
    englishTitle: "",
    hindiDescription: "",
    hindiMaterial: "",
    hindiPurpose: "",
    hindiTitle: "",
    image: '',
    milestoneId: "",
    questionId: "",
    visibleAge: 0,
    isActive: ActivityDayStatusEnum.ACTIVE,
    englishVideo: '',
    hindiVideo: "",
    arabicVideo: "",
    englishVideoTitle: "",
    hindiVideoTitle: "",
    arabicVideoTitle: "",
    englishVideoDescription: "",
    hindiVideoDescription: "",
    arabicVideoDescription: "",
    questionTitle: "",
    milestoneTitle: "",
    categoryTitle: "",
    selectedCategories: [],
    selectedCategoriesForm: {
      categoryId: "",
      categoryTitle: "",
      milestoneId: "",
      milestoneTitle: "",
      question: "",
      questionId: "",
      _id: "",
      id: "",
    },
    englishMaterialList: [],
    hindiMaterialList: [],
    arabicMaterialList: [],
    englishMaterial: "",
    imageStatus: [],
    englishVideoStatus: [],
    hindiVideoStatus: [],
    arabicVideoStatus: [],
    visibleMilestone: ''
  },

  category: "",
  milestone: "",
  disableCheck: false,
  handleChange: undefined,
  sortColumn: "",
  sortDir: "",
  allSortColumn: "",
  allSortDir: "",
  allPageNo: 1,
  allPageSize: 10,
  allTotalRow: 0,
  CategoryMilestoneForm: {
    categoryId: "",
    categoryTitle: "",
    milestoneId: "",
    milestoneTitle: "",
    question: "",
    questionId: "",
    _id: "",
    id: ""
  },
  questionList: [],
  question: '',
  milestoneList: [],
  minAge: 0,
  maxAge: 0,
};

export const useClinicSlice = createSlice({
  name: 'activityState',
  initialState,
  reducers: {
    setDisableCheck: (state, action: PayloadAction<boolean>) => {
      state.disableCheck = action.payload;
    },
    setQuestionList: (state, action: PayloadAction<[]>) => {
      state.questionList = action.payload;
    },
    setMilestoneList: (state, action: PayloadAction<[]>) => {
      state.milestoneList = action.payload;
    },
    
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    },
    setimage: (state, action: PayloadAction<Imagenterface>) => {
      state.form.imageStatus.push(action.payload);
    },

    setEnglishVideoStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.englishVideoStatus[i].isDelete = true;
      }
    },
    setEnglishVideo: (state, action: PayloadAction<englishVideoStatusInterface>) => {
      state.form.englishVideoStatus.push(action.payload);
    },

    setHindiVideoStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.hindiVideoStatus[i].isDelete = true;
      }
    },
    setHindiVideo: (state, action: PayloadAction<hindiVideoStatusInterface>) => {
      state.form.hindiVideoStatus.push(action.payload);
    },

    setArabicVideoStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload.index; i++) {
        state.form.arabicVideoStatus[i].isDelete = true;
      }
    },
    setArabicVideo: (state, action: PayloadAction<arabicVideoStatusInterface>) => {
      state.form.arabicVideoStatus.push(action.payload);
    },

    setQuestion: (state, action: PayloadAction<string>) => {
      state.question = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setAllPageSize: (state, action: PayloadAction<number>) => {
      state.allPageSize = action.payload;
    },
    setAllPageNo: (state, action: PayloadAction<number>) => {
      state.allPageNo = action.payload;
    },
    setAllTotalRow: (state, action: PayloadAction<number>) => {
      state.allTotalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSearchCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    setSearchMileStone: (state, action: PayloadAction<string>) => {
      state.milestone = action.payload;
    },

    setHandleChange: (state, action: PayloadAction<number>) => {
      if (action.payload == 0) {
        state.handleChange = 0;
      } else {
        state.handleChange = state.handleChange + 1;
      }
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    doGetMilestone: (state, actions: PayloadAction<{ callback: () => void }>) => { },
    doUpdate: (state, actions: PayloadAction<{ callback: () => void }>) => { },
    updateSuccess: (state) => {
      state.list[state.editIndex] = state.form;
      state.editIndex = -1;
    },
    clearForm: (state) => {
      state.form.id = "";
      state.form._id = "";
      state.form.englishVideo = '';
      state.form.hindiVideo = "";
      state.form.arabicVideo = "";
      state.form.image = '';
      state.form.englishVideoTitle = "";
      state.form.hindiVideoTitle = "";
      state.form.arabicVideoTitle = "";
      state.form.englishVideoDescription = "";
      state.form.hindiVideoDescription = "";
      state.form.arabicVideoDescription = "";
      state.form.englishTitle = "";
      state.form.hindiTitle = "";
      state.form.arabicTitle = "";
      state.form.englishPurpose = "";
      state.form.hindiPurpose = "";
      state.form.arabicPurpose = "";
      state.form.englishDescription = "";
      state.form.hindiDescription = "";
      state.form.arabicDescription = "";
      state.form.englishMaterial = "";
      state.form.hindiMaterial = "";
      state.form.selectedCategoriesForm = {
        categoryId: "",
        categoryTitle: "",
        milestoneId: "",
        milestoneTitle: "",
        question: "",
        questionId: "",
        _id: "",
        id: "",
      };
      state.form.selectedCategories = [];
      state.form.arabicMaterial = "";
      state.form.visibleAge = 0;
      state.form.minAge = 0;
      state.form.maxAge = 10;
      state.form.categoryId = "";
      state.form.milestoneId = "";
      state.form.questionId = "";
      state.form.isActive = ActivityDayStatusEnum.ACTIVE;
      state.form.questionTitle = "";
      state.form.milestoneTitle = "";
      state.form.categoryTitle = "";
      state.form.englishMaterialList = [];
      state.form.hindiMaterialList = [];
      state.form.arabicMaterialList = [];
      state.form.visibleMilestone = "";
      state.form.imageStatus = [];
      state.form.englishVideoStatus = [];
      state.form.hindiVideoStatus = [];
      state.form.arabicVideoStatus = [];
    },

    doAdd: (state, actions: PayloadAction<{ callback: () => void }>) => { },
    doAddFile: (state, action: PayloadAction<{ key: string; data: any; callback: any }>) => { },
    doGetList: (state, actions: PayloadAction<string>) => { },
    doGetListAll: (state) => { },
    doGetQuestionList: (state) => { },
    doGetEdit: (state, action: PayloadAction<string>) => { },

    setList: (state, action: PayloadAction<Array<ActivityListInterface>>) => {
      state.list = action.payload;
    },
    setListAll: (state, action: PayloadAction<Array<ActivityListInterface>>) => {
      state.listAll = action.payload;
    },
    setFormEdit: (state, action: PayloadAction<ActivityListInterface>) => {
      state.form.id = action.payload.id
      state.form._id = action.payload._id
      state.form.englishVideo = action.payload.englishVideo // should be 3
      state.form.hindiVideo = action.payload.hindiVideo
      state.form.arabicVideo = action.payload.arabicVideo
      state.form.image = action.payload.image
      state.form.englishVideoTitle = action.payload.englishVideoTitle // should be 3
      state.form.hindiVideoTitle = action.payload.hindiVideoTitle
      state.form.arabicVideoTitle = action.payload.arabicVideoTitle
      state.form.englishVideoDescription = action.payload.englishVideoDescription // should be 3
      state.form.hindiVideoDescription = action.payload.hindiVideoDescription
      state.form.arabicVideoDescription = action.payload.arabicVideoDescription
      state.form.englishTitle = action.payload.englishTitle // should be 3
      state.form.hindiTitle = action.payload.hindiTitle
      state.form.arabicTitle = action.payload.arabicTitle
      state.form.englishPurpose = action.payload.englishPurpose // should be 3
      state.form.hindiPurpose = action.payload.hindiPurpose
      state.form.arabicPurpose = action.payload.arabicPurpose
      state.form.englishDescription = action.payload.englishDescription // should be 3
      state.form.hindiDescription = action.payload.hindiDescription
      state.form.arabicDescription = action.payload.arabicDescription
      state.form.englishMaterial = action.payload.englishMaterial // should be 3
      state.form.hindiMaterial = action.payload.hindiMaterial
      state.form.arabicMaterial = action.payload.arabicMaterial
      state.form.visibleAge = action.payload.visibleAge
      state.form.minAge = action.payload.minAge
      state.form.maxAge = action.payload.maxAge
      state.form.categoryId = action.payload.categoryId
      state.form.milestoneId = action.payload.milestoneId
      state.form.questionId = action.payload.questionId
      state.form.isActive = action.payload.isActive
      state.form.questionTitle = action.payload.questionTitle
      state.form.milestoneTitle = action.payload.milestoneTitle
      state.form.categoryTitle = action.payload.categoryTitle
      state.form.selectedCategories = action.payload.selectedCategories
      state.form.selectedCategoriesForm = action.payload.selectedCategoriesForm
      state.form.englishMaterialList = action.payload.englishMaterialList
      state.form.hindiMaterialList = action.payload.hindiMaterialList
      state.form.arabicMaterialList = action.payload.arabicMaterialList
      state.form.visibleMilestone = action.payload.visibleMilestone
      if (action.payload.image) {
        state.form.imageStatus.push({
          image : action.payload.image,
          isDelete: false
        })
      } else {
        state.form.imageStatus.push({
          image: "",
          isDelete: false
        });
      }
      if (action.payload.hindiVideo) {
        state.form.hindiVideoStatus.push({
          hindiVideo : action.payload.hindiVideo,
          isDelete: false
        })
      } else {
        state.form.hindiVideoStatus.push({
          hindiVideo: "",
          isDelete: false
        })
      }
      if (action.payload.englishVideo) {
        state.form.englishVideoStatus.push({
          englishVideo: action.payload.englishVideo,
          isDelete: false
        })
      } else {
        state.form.englishVideoStatus.push({
          englishVideo: "",
          isDelete: false
        })
      }
      if (action.payload.arabicVideo) {
        state.form.arabicVideoStatus.push({
          arabicVideo : action.payload.arabicVideo,
          isDelete: false
        })
      } else {
        state.form.arabicVideoStatus.push({
          arabicVideo: "",
          isDelete: false
        })
      }
    },
    setCategoryFormData: (state, action: PayloadAction<CategoryMilestoneInterface>) => {
      state.CategoryMilestoneForm = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setAllSortColumn: (state, action: PayloadAction<string>) => {
      state.allSortColumn = action.payload;
    },
    setAllSortDir: (state, action: PayloadAction<string>) => {
      state.allSortDir = action.payload;
    },
    // file upload
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => { },
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; result : any;callback: any }>) => { },
    addVideo: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => { },
    doGetCategoryMilestoneById: (state, action: PayloadAction<string>) => { },

    //add categories
    AddCatogries: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.form.selectedCategories.push(state.form.selectedCategoriesForm);
    },
    updateCatogriesFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.selectedCategoriesForm.${action.payload.key}`, action.payload.value);
    },
    clearCatogriesFrom: (state) => {
      state.form.selectedCategoriesForm._id = "";
      state.form.selectedCategoriesForm.id = "";
      state.form.selectedCategoriesForm.categoryId = "";
      state.form.selectedCategoriesForm.categoryTitle = "";
      state.form.selectedCategoriesForm.milestoneId = "";
      state.form.selectedCategoriesForm.milestoneTitle = "";
      state.form.selectedCategoriesForm.question = "";
      state.form.selectedCategoriesForm.questionId = "";
    },
    doDelete: (state, action: PayloadAction<number>) => {
      state.form.selectedCategories.splice(action.payload, 1);
    },
    doDeleteEnglishVideo: (state, action: PayloadAction<number>) => {
      state.form.englishVideo = '';
    },
    doDeleteHindiVideo: (state, action: PayloadAction<number>) => {
      state.form.hindiVideo = "";
    },
    doDeleteArabicVideo: (state, action: PayloadAction<number>) => {
      state.form.arabicVideo = "";
    },
    AddEnglishMaterial: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.form.englishMaterialList.push(state.form.englishMaterial);
    },
    clearEnglishMaterialFrom: (state) => {
      state.form.englishMaterial = "";
    },
    doDeleteEnglishMaterial: (state, action: PayloadAction<number>) => {
      state.form.englishMaterialList.splice(action.payload, 1);
    },
    AddHindiMaterial: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.form.hindiMaterialList.push(state.form.hindiMaterial);
    },
    clearHindiMaterialFrom: (state) => {
      state.form.hindiMaterial = "";
    },
    doDeleteHindiMaterial: (state, action: PayloadAction<number>) => {
      state.form.hindiMaterialList.splice(action.payload, 1);
    },
    AddArabicMaterial: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.form.arabicMaterialList.push(state.form.arabicMaterial);
    },
    clearArabicMaterialFrom: (state) => {
      state.form.arabicMaterial = "";
    },
    doDeleteArabicMaterial: (state, action: PayloadAction<number>) => {
      state.form.arabicMaterialList.splice(action.payload, 1);
    },
    setMinAge: (state, action: PayloadAction<number>) => {
      state.minAge = action.payload;
    },
    setMaxAge: (state, action: PayloadAction<number>) => {
      state.maxAge = action.payload;
    },
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
