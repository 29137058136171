// import { useSelector } from 'react-redux';
// import { Box } from '@mui/material';
// import { RootStateProps } from 'types/root';
// import NavGroup from './NavGroup';
// import menuItem from 'menu-items';
// import { initialState } from 'store/reducers/menu';

// const Navigation = () => {

//   const menu = useSelector((state: RootStateProps) => {
//     if (state) {
//       return state.menu;
//     } else {
//       return initialState;
//     }
//   });
//   const { drawerOpen } = menu;

//   const updatedArray2 = array2.map((obj2: any) => {
//     const matchingObj1 = menuItem(1).items[1]?.children?.find((obj1: any) => obj1.id === obj2.value);    
//     if (matchingObj1) {
//       // Merge values from both arrays if there's a match
//       return {
//         ...obj2,
//         ...matchingObj1,
//         // visible: obj2.rights[0]?.value, 
//       };
//     }

//     return { ...obj2 }; // If no match, retain the original object from array2
//   });
//   const navGroups = updatedArray2?.map((data: any) => <NavGroup key={data.id} item={data} />);

//   return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
// };

// export default Navigation;
import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography, } from '@mui/material';

// types
import { RootStateProps } from 'types/root';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { selectStaffPermishion } from 'sections/auth/redux/selector';
import { initialState } from 'store/reducers/menu';
const Navigation = () => {
  const menu = useSelector((state: RootStateProps) => {
    if (state) {
      return state.menu;
    } else {
      return initialState;
    }
  });
  const { drawerOpen } = menu;
  const array2 = useSelector(selectStaffPermishion);
  const navGroups = menuItem(1).items.filter((x:any)=>{
   if( x.id=="group-custom-dashboard")
   {
    if(array2 && array2?.length>0)
    { 
      x.children= x.children?.filter((y:any)=>{
        console.log("y.id qs",y.id,array2,array2.filter((z:any) => z.value == y.id));
        
        if(array2.filter((z:any) => z.value == y.id).length > 0)
        {  
          const rights= array2.filter((w:any) => w.value == y.id)[0].rights.filter((x:any)=>x.name=="can_view")[0].value;
          if(rights==1)
          {
            return true;
          }
          return  false;
         
        }
        return false
        
        
      })
      return true;

    }else{
      return true;
    }

   
   }else{
    return true;
   }
  
  }).map((item) => {
    switch (item.type) {  
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  // const updatedArray2 = array2.map((obj2: any) => {
  //   const matchingObj1 = menuItem(1).items[1]?.children?.find((obj1: any) => obj1.id === obj2.value);    
  //   if (matchingObj1) {
  //     // Merge values from both arrays if there's a match
  //     return {
  //       ...obj2,
  //       ...matchingObj1,
  //       // visible: obj2.rights[0]?.value, 
  //     };
  //   }

  //   return { ...obj2 }; // If no match, retain the original object from array2
  // });
  // console.log(updatedArray2 , "updatedArray2");
  
  // const navGroups = updatedArray2?.map((data: any) => <NavGroup key={data.id} item={data} />);
  console.log(navGroups , "navGroups",array2);
  
  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
