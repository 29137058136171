

export interface ToysCategoryInterface {
  _id: string;
  id: string;
  englishTitle: string; // should be 3
  hindiTitle: string;
  arabicTitle: string;
  isActive: ActivityEnum;
}
export enum ActivityEnum {
  NO = 0,
  YES = 1
}

export interface ToysCategoryState {
  list: ToysCategoryInterface[];
  form: ToysCategoryInterface;
  listAll: ToysCategoryInterface[];
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  
}

export type InitialToysCategoryState = ToysCategoryState;
