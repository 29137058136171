import dashboard from './dashboard';
import { NavItemType } from 'types/menu';
import custom from './custom';
import { RolesEnum } from 'sections/auth/types';

// ==============================|| MENU ITEMS ||============================== //

const menuItemss = (Role?: RolesEnum): { items: NavItemType[] } => {

  console.log('====================================');
  console.log('Role', Role, dashboard, custom,);
  console.log('====================================');

  // const rightsCustom = custom;
  // rightsCustom.children = custom.children?.filter((x) => {
  //   console.log("Role rights",permission?.filter((y) => y.value == x.id).length);
  //   // return true
  //   if (permission && permission.filter((y) => y.value == x.id).length > 0) {
  //     // const rights= permission && permission.filter((y) => y.value == x.id)[0].rights.filter((x:any)=>x.name=="can_view")[0].value;
      
      
  //     return true

  //   } else {
  //     return false
  //   }

  // })
  const menuItems: { items: NavItemType[] } = {
    items: [dashboard, custom]
  };

  return menuItems;
};

export default menuItemss;
