import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import CatchBlockFunction from 'hooks/CatchBlockFunction';
import { SelectStartAndEndDate, SelectdateFilter } from './selector';
import { doGetDashboardChartData, doGetDashboardChartDataRequest, doGetDashboardData } from 'utils copy/request';
import { startAndEndDateInterface } from '../types';
export function* getDashboardListRequest() {
  yield delay(500);
  const dateFIlter: number = yield select(SelectdateFilter);
  const startDateEndDate: startAndEndDateInterface = yield select(SelectStartAndEndDate);

  try {
    const response: AxiosResponse = yield call(
      doGetDashboardData,
      `?dateFilter=${dateFIlter}&startDate=${startDateEndDate.startDate}&endDate=${startDateEndDate.endDate}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      return;
    }

    yield put(actions.setDashboardsList(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* getDashboardChartDataRequest() {
  yield delay(500);
  const dateFIlter: number = yield select(SelectdateFilter);
  const startDateEndDate: startAndEndDateInterface = yield select(SelectStartAndEndDate);
  try {
    const response: AxiosResponse = yield call(
      doGetDashboardChartData,
      `?dateFilter=${dateFIlter}&startDate=${startDateEndDate.startDate}&endDate=${startDateEndDate.endDate}`
    );
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      return;
    }
    yield put(actions.setChartData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetRecentListRequest() {
  yield delay(500);
  try {
    const response: AxiosResponse = yield call(doGetDashboardChartDataRequest);
    yield put(actions.setLoading(false));
    if (response.data.status == false) {
      return;
    }
    debugger
    yield put(actions.setRecentPlanData(response.data.data));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

export function* dashboardRepoSagaDashboard() {
  yield takeLatest(actions.doGetDashboardList, getDashboardListRequest);
  yield takeLatest(actions.doGetRecentList, doGetRecentListRequest);
  yield takeLatest(actions.getDashboardChartData, getDashboardChartDataRequest);

}
