import { PayloadAction } from '@reduxjs/toolkit';
import { VaccinationInterface, VaccinationState } from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: VaccinationState = {
  loading: false,
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  form: {
    id: '',
    englishName: '',
    hindiName: '',
    arabicName: '',
    englishProtectionAgainst: '',
    hindiProtectionAgainst: '',
    arabicProtectionAgainst: '',
    englishInstructions: '',
    hindiInstructions: '',
    arabicInstructions: '',
    toBeGiven: '',
    months: 0,
    dose: 0,
    totalDose: 0,
    _id: '',
    isActive: 1,
    vaccineDuration: 1,
    vaccineVisibility: "",
    vaccineDays: ''
  },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'vaccinationState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload.key}`, action.payload.value);
    },
    clearForm: (state) => {
      state.form.englishName = '';
      state.form.hindiName = '';
      state.form.arabicName = '';
      state.form.englishProtectionAgainst = '';
      state.form.hindiProtectionAgainst = '';
      state.form.arabicProtectionAgainst = '';
      state.form.englishInstructions = '';
      state.form.hindiInstructions = '';
      state.form.arabicInstructions = '';
      state.form.toBeGiven = '';
      state.form.months = 0;
      state.form.dose = 0;
      state.form.totalDose = 0;
      state.form.id = '';
      state.form._id = '';
      state.form.isActive = 1;
      // state.form.vaccinationDoses = [];
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},

    setList: (state, action: PayloadAction<Array<VaccinationInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<VaccinationInterface>) => {
      state.form = action.payload
      // state.form.vaccinationDosesForm = action.payload.vaccinationDosesForm;
    },
    updateVaccinationDosesFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.vaccinationDosesForm.${action.payload.key}`, action.payload.value);
    },
    // clearVaccinationDosesFrom: (state) => {
    //   state.form.vaccinationDosesForm.months = 0;
    //   state.form.vaccinationDosesForm.dose = 0;
    // },
    doAddDoses: (state, action: PayloadAction<{ callback: () => void }>) => {
      // state.form.vaccinationDoses.push(state.form.vaccinationDosesForm);
    },


  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
