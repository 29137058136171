import { PayloadAction } from '@reduxjs/toolkit';
import {
  MealInterface,
  MealRecipeEnum,
  MealStatusEnum,
  Imagenterface,
  englishVideoStatusInterface,
  hindiVideoStatusInterface,
  arabicVideoStatusInterface,
  TypeEnum,
  MealFunFoodState
} from '../types';
import { createSlice } from 'utils copy/@reduxjs/toolkit';
import { set } from 'lodash';

export const initialState: MealFunFoodState = {
  loading: false,
  list: [],
  editIndex: -1,
  isadd: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  form: {
    id: '',
    _id: '',
    image: '',
    mealType: [],
    type: TypeEnum.VEG,
    englishName: '',
    hindiName: '',
    arabicName: '',
    recipeType: MealRecipeEnum.NORMAL,
    englishDescription: '',
    hindiDescription: '',
    arabicDescription: '',
    isActive: MealStatusEnum.ACTIVE,
    englishVideo: '',
    hindiVideo: '',
    arabicVideo: '',
    maxPermit: 0,
    minPermit: 0,
    englishVideoStatus: [],
    hindiVideoStatus: [],
    arabicVideoStatus: [],
    imageStatus: [],
    mealCategory: '',
    minAge: 0,
    maxAge: 10,
    youTubeLink: '',
    mealIngredients: [],
    ingredientsForm: {
      ingredientId: "",
      ingredientQuantity: '',
      englishTitle:""
    }
  },
  sortColumn: '',
  sortDir: ''
};

export const useClinicSlice = createSlice({
  name: 'mealsFunFoodState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setImageStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload?.index; i++) {
        state.form.imageStatus[i].isDelete = true;
      }
    },
    setimage: (state, action: PayloadAction<Imagenterface>) => {
      state.form.imageStatus.push(action.payload);
    },

    setEnglishVideoStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload?.index; i++) {
        state.form.englishVideoStatus[i].isDelete = true;
      }
    },
    doDelete: (state, action: PayloadAction<number>) => {
      state.form.mealIngredients.splice(action.payload, 1);
    },
    setEnglishVideo: (state, action: PayloadAction<englishVideoStatusInterface>) => {
      state.form.englishVideoStatus.push(action.payload);
    },

    setHindiVideoStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload?.index; i++) {
        state.form.hindiVideoStatus[i].isDelete = true;
      }
    },
    setHindiVideo: (state, action: PayloadAction<hindiVideoStatusInterface>) => {
      state.form.hindiVideoStatus.push(action.payload);
    },

    setArabicVideoStatusChanage: (state, action: PayloadAction<{ index: number }>) => {
      for (let i = 0; i <= action.payload?.index; i++) {
        state.form.arabicVideoStatus[i].isDelete = true;
      }
    },
    setArabicVideo: (state, action: PayloadAction<arabicVideoStatusInterface>) => {
      state.form.arabicVideoStatus.push(action.payload);
    },

    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    updateFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.${action.payload?.key}`, action.payload?.value);
    },
    updateIngredientFormValue: (state, action: PayloadAction<{ key: string; value: any }>) => {
      set(state, `form.ingredientsForm.${action.payload?.key}`, action.payload?.value);
    },
    clearForm: (state) => {
      state.form.englishName = '';
      state.form.hindiName = '';
      state.form.arabicName = '';
      state.form.englishDescription = '';
      state.form.hindiDescription = '';
      state.form.arabicDescription = '';
      state.form.englishVideo = '';
      state.form.hindiVideo = '';
      state.form.arabicVideo = '';
      state.form.maxPermit = 0;
      state.form.minPermit = 0;
      state.form.youTubeLink = '';
      state.form.arabicVideo = '';
      state.form.image = '';
      state.form.id = '';
      state.form._id = '';
      state.form.mealType = [];
      state.form.recipeType = MealRecipeEnum.NORMAL;
      state.form.isActive = MealStatusEnum.ACTIVE;
      state.form.type = TypeEnum.VEG;
      state.form.mealCategory = '';
      state.form.ingredientsForm.ingredientId = "";
      state.form.ingredientsForm.ingredientQuantity = "";
      state.form.minAge = 0;
      state.form.maxAge = 10;
      state.form.imageStatus = [];
      state.form.englishVideoStatus = [];
      state.form.hindiVideoStatus = [];
      state.form.arabicVideoStatus = [];
    },
    doAdd: (state, action: PayloadAction<{ callback: () => void }>) => {},
    doGetList: (state) => {},
    doGetEdit: (state, action: PayloadAction<string>) => {},
    doUpdate: (state, action: PayloadAction<{ callback: () => void }>) => {},
    setList: (state, action: PayloadAction<Array<MealInterface>>) => {
      state.list = action.payload;
    },
    setDataForEdit: (state, action: PayloadAction<MealInterface>) => {
      state.form.englishName = action.payload?.englishName;
      state.form.hindiName = action.payload?.hindiName;
      state.form.arabicName = action.payload?.arabicName;
      state.form.englishDescription = action.payload?.englishDescription;
      state.form.hindiDescription = action.payload?.hindiDescription;
      state.form.arabicDescription = action.payload?.arabicDescription;
      state.form.englishVideo = action.payload?.englishVideo;
      state.form.hindiVideo = action.payload?.hindiVideo;
      state.form.arabicVideo = action.payload?.arabicVideo;
      state.form.maxPermit = action.payload?.maxPermit;
      state.form.minPermit = action.payload?.minPermit;
      state.form.image = action.payload?.image;
      state.form.id = action.payload?.id;
      state.form._id = action.payload?._id;
      state.form.mealType = action.payload?.mealType;
      state.form.recipeType = action.payload?.recipeType;
      state.form.isActive = action.payload?.isActive;
      state.form.mealCategory = action.payload?.mealCategory;
      state.form.mealIngredients = action.payload?.mealIngredients;
      state.form.type = action.payload?.type;
      state.form.minAge = action.payload?.minAge;
      state.form.maxAge = action.payload?.maxAge;
      state.form.youTubeLink = action.payload?.youTubeLink ? action.payload?.youTubeLink : '';
      if (action.payload?.image) {
        state.form.imageStatus.push({
          image: action.payload?.image,
          isDelete: false
        });
      } else {
        state.form.imageStatus.push({
          image: '',
          isDelete: false
        });
      }
      if (action.payload?.hindiVideo) {
        state.form.hindiVideoStatus.push({
          hindiVideo: action.payload?.hindiVideo,
          isDelete: false
        });
      } else {
        state.form.hindiVideoStatus.push({
          hindiVideo: '',
          isDelete: false
        });
      }
      if (action.payload?.englishVideo) {
        state.form.englishVideoStatus.push({
          englishVideo: action.payload?.englishVideo,
          isDelete: false
        });
      } else {
        state.form.englishVideoStatus.push({
          englishVideo: '',
          isDelete: false
        });
      }
      if (action.payload?.arabicVideo) {
        state.form.arabicVideoStatus.push({
          arabicVideo: action.payload?.arabicVideo,
          isDelete: false
        });
      } else {
        state.form.arabicVideoStatus.push({
          arabicVideo: '',
          isDelete: false
        });
      }
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    addImage: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    addVideo: (
      state,
      action: PayloadAction<{
        data: any;
        type: number;
        callback: (signedUrl: string, fileNameWithPrefix: string) => void;
      }>
    ) => {},
    UploadeImage: (state, action: PayloadAction<{ data: any; signedUrl: string; result: any; callback: any }>) => {},
    addIngredientsList: (state) => {
      state.form.mealIngredients.push(state.form.ingredientsForm)
    },
    



    clearIngredientsForm: (state,) => {
      state.form.ingredientsForm.ingredientId = "";
      state.form.ingredientsForm.ingredientQuantity = "";
    },

    setMealType: (state, action: PayloadAction<any>) => {
      state.form.mealType = action.payload;
    }
  }
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
