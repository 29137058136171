import { ActivityDayStatusEnum } from '../types';

export interface MilestoneCategoryInterface {
  _id: string;
  id: string;
  englishTitle: string; // should be 3
  hindiTitle: string;
  arabicTitle: string;
  visible: visibleEnum;
  isActive: ActivityDayStatusEnum;
  colorCode: string;
  order:number;
}
export enum visibleEnum {
  visible = 1,
  notVisible = 0
}

export interface MileStoneCategoryState {
  list: MilestoneCategoryInterface[];
  form: MilestoneCategoryInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  englishTitle: MilestoneCategoryInterface[];
}

export type InitialMileStoneCatergoryStata = MileStoneCategoryState;
