import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { MealsIngredientInterface } from '../types';
import { SelectPageNo, SelectPageSize, SelectSearch, SelectSortColumn, SelectSortDir, selectForm } from './selector';
import {
  doGetMealsIngredientById,
  doUpdateMealsIngredient,
  doGetMealsIngredientListAll,
  doGetMealsIngredientList,
  doAddMealsIngredient,
  getDayIngridintImageRequest,
  uplodeDayAcivityImageRequest
} from 'utils copy/request';
import { openSnackbar } from 'store/reducers/snackbar';
import CatchBlockFunction from 'hooks/CatchBlockFunction';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: MealsIngredientInterface = yield select(selectForm);

  if (form.englishTitle.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english ingredients name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(doAddMealsIngredient, form);
    yield put(actions.setLoading(false));
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(SelectPageNo);
    const pageSize: number = yield select(SelectPageSize);
    const searchText: string = yield select(SelectSearch);
    const SortColumn: string = yield select(SelectSortColumn);
    const SortDir: string = yield select(SelectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      doGetMealsIngredientList,
      `?pageNo=${pageNo}&pageSize=${pageSize}${searchText.length > 0 ? '&search=' + searchText : ''}${
        SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''
      }${SortDir.length > 0 ? '&sortDir=' + SortDir : ''}`
    );

    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(actions.setTotalRow(response.data.data.meta ? response.data.data.meta.total : 0));
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(doGetMealsIngredientById, action.payload);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: MealsIngredientInterface = yield select(selectForm);

  if (form.englishTitle.length === 0) {
    yield put(
      openSnackbar({
        open: true,
        message: 'Please fill english ingredients name',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
    return;
  }

  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doUpdateMealsIngredient, form);
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield put(
      openSnackbar({
        open: true,
        message: response.data.message,
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: true
      })
    );
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetAllListRequest() {
  yield delay(500);
  try {
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(doGetMealsIngredientListAll);
    if (response && !response.data) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }

    yield put(actions.setAllList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* FileUploadRequest(action: { payload: { data: any; type: number; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(getDayIngridintImageRequest, action.payload.data);
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    }
    yield call(action?.payload?.callback(response.data.signedUrl, response.data.fileNameWithPrefix));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* UploadRequest(action: { payload: { data: any; signedUrl: string; result: any; callback: any } }) {
  yield delay(500);

  try {
    const response: AxiosResponse = yield call(
      uplodeDayAcivityImageRequest,
      action.payload.signedUrl,
      action.payload.data,
      action.payload.result
    );
    if (!response.status) {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: true
        })
      );
      return;
    } else {
      yield put(
        openSnackbar({
          open: true,
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    }
    yield call(action?.payload?.callback());
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}
export function* MealIngredientsListRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doGetAllList, doGetAllListRequest);
  yield takeLatest(actions.addImage, FileUploadRequest);
  yield takeLatest(actions.UploadeImage, UploadRequest);
}
