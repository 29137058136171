export interface HelpMeInterface {
  id: string;
  _id: string;
  englishQuestion: string;
  hindiQuestion: string;
  arabicQuestion: string;
  englishAnswer: string;
  hindiAnswer: string;
  arabicAnswer: string;
  isActive: IsActiveEnum;
}
export enum IsActiveEnum {
  INACTIVE = 0,
  ACTIVE = 1
}
export interface HelpMeState {
  List: HelpMeInterface[];
  formData: HelpMeInterface;
  editIndex: number;
  loading: boolean;
  isadd: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
}

export type InitialHelpMeState = HelpMeState;
